import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { AdDuration, AdFormat, useAdsList } from '../../api/ads';
import Page from '../../components/Page';
import Loader from '../../components/Loader';
import AdsList from './AdsList';
import AdsFilter, { defaultFilter } from './AdsFilter';
import AdDetails from './AdDetails';
import { useGlobalStyles } from '../../theme/useGlobalStyles';
import { useGroupsList } from '../../api/groups';
import { useAdvertisersList } from '../../api/advertisers';
import useFilter from '../../hooks/useFilter';

const emptyAd = {
    name: '',
    schedule: [],
    format: AdFormat.FULLSCREEN,
    showDuration: AdDuration.DURATION_5S,
    advertiser: { id: '' },
    groups: [],
    active: true,
    editable: [
        'name',
        'title',
        'shortText',
        'mainText',
        'showDuration',
        'schedule',
        'format',
        'advertiser',
        'groups',
        'isPublished',
    ],
};

export default function AdsPage() {
    const { id } = useParams();
    const classes = useGlobalStyles();
    const [ setFilter, filter ] = useFilter(defaultFilter);
    const [ { items }, loading, load ] = useAdsList();
    const [ { items: groups }, loadingGroups, loadGroups ] = useGroupsList();
    const [ { items: advertisers }, loadingAdvertisers, loadAdvertisers ] = useAdvertisersList();
    const [ details, setDetails ] = useState<any>();
    const navigate = useNavigate();

    useEffect(() => {
        load(filter);
        loadGroups({ active: true });
        loadAdvertisers({});
    }, [ filter ]);

    useEffect(() => {
        if (id) {
            if (id === 'new') {
                setDetails(emptyAd);
            } else if (items.length) {
                setDetails(items.find((i: any) => i.id === parseInt(id)));
            }
        } else {
            setDetails(undefined);
        }
    }, [ id, items ]);

    return (
        <Page className={classes.root} title="Реклама" action={(
            <Button variant="contained" color="primary" onClick={() => navigate('/app/ads/new')}>
                Добавить объявление
            </Button>
        )}>
            <div className="filters-wrapper">
                <AdsFilter  filter={filter} onApply={setFilter} groups={groups} advertisers={advertisers}/>
            </div>

            <Container maxWidth={false}>
                <Box mt={3}>
                    <Loader loading={loading || loadingGroups || loadingAdvertisers}>
                        <AdsList ads={items} onRowClick={(id: number) => navigate(`/app/ads/${id}`)}/>
                    </Loader>
                </Box>
            </Container>
            {details && (
                <AdDetails item={details}
                           onSave={() => load(filter)}
                           onClose={() => navigate('/app/ads')}
                           groups={groups}
                           advertisers={advertisers}/>
            )}
        </Page>
    );
};

import { IBoard } from './boards';
import { AdminApi, useFetchApi } from './index';


export interface IGroup {
    id: number;
    name: string;
    description: string;
    boards: IBoard[];
    internal: boolean;
    isAdminRecommended: boolean;
}

export interface IGroupsListRequest {
    name?: number;
    internal?: boolean;
    active?: boolean;
}

export function useGroupsList() {
    return useFetchApi(
        async (query: IGroupsListRequest) => AdminApi.post('/group/list', query),
        { items: [] },
    );
}
